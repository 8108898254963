import { adminApi } from '@link/gds-supplier/services';
import { ListBackendsResp } from '@link/gds-supplier/services/types';
import { action, computed, makeObservable, observable } from 'mobx';

const storage = sessionStorage;
const BACKEND = 'backend';
export class Backend {
  @observable
  accessor backend: ListBackendsResp = JSON.parse(
    storage.getItem(BACKEND) || '{}'
  );
  constructor() {
    makeObservable(this);
  }
  @action
  setBackend(backend: ListBackendsResp) {
    storage.setItem(BACKEND, JSON.stringify(backend));
    this.backend = backend;
  }
  @action
  listBackends() {
    if (!this.loaded) {
      adminApi.listBackends().then(res => {
        if (res) {
          this.setBackend(res);
        }
      });
    }
  }
  @computed
  get options() {
    return (
      this.backend.backends?.map(option => ({
        label: option.backendName as string,
        value: option.backendId as string
      })) || []
    );
  }

  @computed
  get backCodeInstructions() {
    return this.backend.backendCodeInstructions || [];
  }
  @computed
  get startTimeCodeInstructions() {
    return this.backend.startTimeCodeInstructions || [];
  }
  @computed
  get unitCodeInstructions() {
    return this.backend.unitCodeInstructions || [];
  }
  @computed
  get poolIdInstructions() {
    return this.backend.poolIdInstructions || [];
  }
  @computed
  get loaded() {
    return !!this.backend.backends;
  }
}

export default new Backend();
