import { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';
import logoUrl from '@link/gds-share/assets/logo.png';
import { Breadcrumb } from '@linktivity/link-ui';
import { useStore } from '@link/gds-supplier/stores';
import Supplier from './Supplier';
import User from './User';
import RelatedLinks from './RelatedLinks';
import styles from './header.module.css';

const Header = () => {
  const { breadcrumb } = useStore();
  const showBreadcrumb = useMemo(() => {
    return (
      breadcrumb.routes.length > 0 &&
      breadcrumb.routes.every(route => route.name?.trim() !== '')
    );
  }, [breadcrumb.routes]);

  return (
    <header className={styles.header}>
      <div className={styles.inner}>
        <Link to="/" className={styles.logo}>
          <img src={logoUrl} alt="logo" width="160" />
        </Link>

        {showBreadcrumb && (
          <Breadcrumb className={styles.breadcrumb}>
            {breadcrumb.routes.map((route, index) => (
              <Breadcrumb.Item
                key={index}
                to={route.to}
                className={styles.breadcrumbItem}
              >
                {route.name}
              </Breadcrumb.Item>
            ))}
          </Breadcrumb>
        )}
        <div className={styles.info}>
          <Supplier />
          <RelatedLinks />
          <User />
        </div>
      </div>
    </header>
  );
};
const ObserverHeader = observer(Header);
export default ObserverHeader;
