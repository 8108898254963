import { useEffect } from 'react';
import { useParams, Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { Loader } from '@link/gds-share/components';
import { ROUTE_PATH } from '@link/gds-share/constants/path';
import { useBreadcrumb } from '@link/gds-supplier/hooks';
import { useStore } from '@link/gds-supplier/stores';
import Header from '../Header';
import Sidebar from './Sidebar';
import styles from './activity.module.css';

const ActivityLayout = () => {
  const { t } = useTranslation();
  const { activity, memo, breadcrumb } = useStore();
  const { setBreadcrumbRoutes } = useBreadcrumb(activity);
  const { activityId } = useParams();

  useEffect(() => {
    const routes = [
      {
        to: `/${ROUTE_PATH.activities}`,
        name: t('layouts.sidebar.activities')
      }
    ];
    setBreadcrumbRoutes(routes);

    return () => {
      breadcrumb.clear();
    };
  }, [breadcrumb, t, setBreadcrumbRoutes]);

  useEffect(() => {
    if (activityId) {
      activity.clearActivity();
      activity.getActivity(activityId);
      memo.getMemo(activityId);
    }
  }, [activity, activityId, memo]);

  return (
    <div className={styles.activity}>
      <Header />
      <div className={styles.container}>
        <Sidebar />
        <main className={styles.main}>
          <div className={styles.inner}>
            {activity.loaded ? <Outlet /> : <Loader />}
          </div>
        </main>
      </div>
    </div>
  );
};
const ObserverActivityLayout = observer(ActivityLayout);
export default ObserverActivityLayout;
