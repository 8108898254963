import { useCallback, useEffect, useState } from 'react';
import cls from 'clsx';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { Loading, Select, message } from '@linktivity/link-ui';
import { RefreshIcon } from '@link/gds-share/icons';
import { ISupplierOption } from '@link/gds-share/services/types';
import styles from './select.module.css';

type SupplierSelectProps = {
  onSelect?: (supplierId: string) => void;
  supplierId?: string;
  refresh?: () => void;
  supplierOptions: ISupplierOption[];
  loading: boolean;
};
const SupplierSelect: React.FC<SupplierSelectProps> = ({
  supplierId,
  onSelect,
  refresh,
  supplierOptions,
  loading
}) => {
  const { t } = useTranslation();

  const [isRefresh, setIsRefresh] = useState(false);
  const refreshSupplier = useCallback(() => {
    refresh?.();
    setIsRefresh(true);
  }, [refresh]);

  useEffect(() => {
    if (!loading && isRefresh) {
      message.success(t('share.common.updateSuccess'));
      setIsRefresh(false);
    }
  }, [loading, isRefresh, t]);

  return (
    <div className={styles.container}>
      <Select
        options={supplierOptions}
        value={supplierId}
        onSelect={onSelect}
        placeholder={t('share.supplierSelect.selectSupplier')}
        className={styles.select}
        withinPortal={false}
        virtualScroll
        clearable
        filterable
      />
      {!!refresh &&
        (loading ? (
          <Loading className={cls(styles.icon, styles.loading)} />
        ) : (
          <RefreshIcon
            className={cls(styles.icon, styles.refresh)}
            onClick={refreshSupplier}
          />
        ))}
    </div>
  );
};
const SupplierSelectView = observer(SupplierSelect);
export default SupplierSelectView;
