import React from 'react';
import activity, { Activity } from './modules/activity';
import auth, { Auth } from './modules/auth';
import allotment, { Allotment } from './modules/allotment';
import breadcrumb, { Breadcrumb } from './modules/breadcrumb';
import contract, { Contract } from './modules/contract';
import inventory, { Inventory } from './modules/inventory';
import ota, { Ota } from './modules/ota';
import plan, { Plan } from './modules/plan';
import supplier, { Supplier } from './modules/supplier';
import memo, { Memo } from './modules/memo';
import backend, { Backend } from './modules/backend';

export type RootStore = {
  activity: Activity;
  auth: Auth;
  allotment: Allotment;
  breadcrumb: Breadcrumb;
  contract: Contract;
  inventory: Inventory;
  ota: Ota;
  plan: Plan;
  supplier: Supplier;
  memo: Memo;
  backend: Backend;
};

export const store: RootStore = {
  activity,
  auth,
  allotment,
  breadcrumb,
  contract,
  inventory,
  ota,
  plan,
  supplier,
  memo,
  backend
};

const StoreContext = React.createContext<RootStore>(store);

export const StoreProvider = StoreContext.Provider;

export const useStore = () => React.useContext(StoreContext);

export { activity, auth, allotment, breadcrumb, inventory, supplier };
