/* eslint-disable react-refresh/only-export-components */
import { lazy, Suspense } from 'react';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  Navigate,
  useRouteError
} from 'react-router-dom';
import {
  ActivityLayout,
  AllotmentLayout,
  BackendLayout,
  BaseLayout,
  PlanLayout
} from '@link/gds-supplier/layouts';
import { Loader } from '@link/gds-share/components';
import { ROUTE_PATH } from '@link/gds-share/constants/path';
import PrivateRoute from './PrivateRoute';
import AdminRoute from './AdminRoute';
import SupplierAdminRoute from './SupplierAdminRoute';

const Dashboard = lazy(() => import('@link/gds-supplier/views/Dashboard'));

const Activities = lazy(() => import('@link/gds-supplier/views/Activities'));

const AllotmentSettings = lazy(
  () => import('@link/gds-supplier/views/AllotmentSettings')
);
const AllotmentSetting = lazy(
  () => import('@link/gds-supplier/views/AllotmentSetting/Setting')
);
const AllotmentSegmentId = lazy(
  () => import('@link/gds-supplier/views/AllotmentSetting/SegmentId')
);
const AllotmentException = lazy(
  () => import('@link/gds-supplier/views/AllotmentSetting/Exception')
);
const AllotmentCalendar = lazy(
  () => import('@link/gds-supplier/views/AllotmentSetting/Calendar')
);
const AllotmentOtaGroup = lazy(
  () => import('@link/gds-supplier/views/AllotmentSetting/OtaGroup')
);
const AllotmentBackend = lazy(
  () => import('@link/gds-supplier/views/AllotmentSetting/Backend')
);

const BackendSettings = lazy(
  () => import('@link/gds-supplier/views/BackendSettings')
);
const BackendInventory = lazy(
  () => import('@link/gds-supplier/views/BackendSetting/Inventory')
);
const BackendCalendar = lazy(
  () => import('@link/gds-supplier/views/BackendSetting/Calendar')
);

const ActivityInfo = lazy(
  () => import('@link/gds-supplier/views/Activity/Info')
);
const ActivityDetail = lazy(
  () => import('@link/gds-supplier/views/Activity/Detail')
);
const ActivityAgent = lazy(
  () => import('@link/gds-supplier/views/Activity/Agent')
);
const ActivityI18n = lazy(
  () => import('@link/gds-supplier/views/Activity/I18n')
);

const ActivityEditLog = lazy(
  () => import('@link/gds-supplier/views/Activity/EditLog')
);
const ActivityMemo = lazy(
  () => import('@link/gds-supplier/views/Activity/Memo')
);
const PlanInfo = lazy(() => import('@link/gds-supplier/views/Plan/Info'));

const PlanDetail = lazy(() => import('@link/gds-supplier/views/Plan/Detail'));
const PlanBooking = lazy(() => import('@link/gds-supplier/views/Plan/Booking'));
const PlanI18n = lazy(() => import('@link/gds-supplier/views/Plan/I18n'));
const PlanPrice = lazy(() => import('@link/gds-supplier/views/Plan/Price'));
const PlanSetCode = lazy(
  () => import('@link/gds-supplier/views/Activity/Admin/SetCode')
);
const AdminActivity = lazy(
  () => import('@link/gds-supplier/views/Activity/Admin/Activity')
);

const Login = lazy(() => import('@link/gds-supplier/views/Login'));

const ChangePassword = lazy(
  () => import('@link/gds-supplier/views/ChangePassword')
);
const ResetPassword = lazy(
  () => import('@link/gds-supplier/views/ResetPassword')
);
const NotFound = lazy(() => import('@link/gds-share/views/NotFound'));

function RootErrorBoundary() {
  const error = useRouteError() as Error;
  if (error.message.includes('Failed to fetch dynamically imported module')) {
    location.reload();
  }
  return null;
}

const lazyView = (view: JSX.Element) => {
  return <Suspense fallback={<Loader />}>{view}</Suspense>;
};

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route errorElement={<RootErrorBoundary />}>
      <Route element={<PrivateRoute />}>
        <Route path="/" element={<BaseLayout />}>
          <Route
            index
            element={<Navigate to={`/${ROUTE_PATH.activities}`} replace />}
          />
          <Route
            path={ROUTE_PATH.dashboard}
            element={lazyView(<Dashboard />)}
          />
          <Route
            path={ROUTE_PATH.activities}
            element={lazyView(<Activities />)}
          />
          <Route element={<SupplierAdminRoute />}>
            <Route
              path={ROUTE_PATH.allotmentSettings}
              element={lazyView(<AllotmentSettings />)}
            />
          </Route>
          <Route element={<AdminRoute />}>
            <Route
              path={`${ROUTE_PATH.backendSettings}`}
              element={lazyView(<BackendSettings />)}
            />
          </Route>
          <Route
            path={`/${ROUTE_PATH.changePassword}`}
            element={lazyView(<ChangePassword />)}
          />
          <Route path="*" element={lazyView(<NotFound />)} />
        </Route>

        <Route
          path={`/${ROUTE_PATH.activity}/:activityId`}
          element={<ActivityLayout />}
        >
          <Route index element={<Navigate to={ROUTE_PATH.info} replace />} />
          <Route
            path={ROUTE_PATH.info}
            element={lazyView(<ActivityInfo />)}
            handle={{ breadcrumb: 'layouts.sidebar.info' }}
          />
          <Route
            path={ROUTE_PATH.detail}
            element={lazyView(<ActivityDetail />)}
            handle={{ breadcrumb: 'layouts.sidebar.detail' }}
          />
          <Route
            path={ROUTE_PATH.agent}
            element={lazyView(<ActivityAgent />)}
            handle={{ breadcrumb: 'layouts.sidebar.agent' }}
          />
          <Route
            path={ROUTE_PATH.i18n}
            element={lazyView(<ActivityI18n />)}
            handle={{ breadcrumb: 'layouts.sidebar.i18n' }}
          />
          <Route
            path={ROUTE_PATH.editLog}
            element={lazyView(<ActivityEditLog />)}
            handle={{ breadcrumb: 'layouts.sidebar.editLog' }}
          />
          <Route
            path={ROUTE_PATH.memo}
            element={lazyView(<ActivityMemo />)}
            handle={{ breadcrumb: 'layouts.sidebar.editMemo' }}
          />
          <Route
            path={ROUTE_PATH.admin}
            element={<AdminRoute />}
            handle={{ breadcrumb: 'layouts.sidebar.adminSetting' }}
          >
            <Route
              index
              element={<Navigate to={ROUTE_PATH.activity} replace />}
            />
            <Route
              path={ROUTE_PATH.activity}
              element={lazyView(<AdminActivity />)}
              handle={{ breadcrumb: 'layouts.sidebar.adminActivity' }}
            />
          </Route>
          <Route path=":planId" element={<PlanLayout />}>
            <Route index element={<Navigate to={ROUTE_PATH.info} replace />} />
            <Route
              path={ROUTE_PATH.info}
              element={lazyView(<PlanInfo />)}
              handle={{ breadcrumb: 'layouts.sidebar.info' }}
            />
            <Route
              path={ROUTE_PATH.detail}
              element={lazyView(<PlanDetail />)}
              handle={{ breadcrumb: 'layouts.sidebar.detail' }}
            />
            <Route
              path={ROUTE_PATH.booking}
              element={lazyView(<PlanBooking />)}
              handle={{ breadcrumb: 'layouts.sidebar.booking' }}
            />
            <Route
              path={ROUTE_PATH.i18n}
              element={lazyView(<PlanI18n />)}
              handle={{ breadcrumb: 'layouts.sidebar.i18n' }}
            />
            <Route
              path={ROUTE_PATH.price}
              element={lazyView(<PlanPrice />)}
              handle={{ breadcrumb: 'layouts.sidebar.price' }}
            />
          </Route>
          <Route
            path={ROUTE_PATH.admin}
            element={<PlanLayout />}
            handle={{ breadcrumb: 'layouts.sidebar.adminSetting' }}
          >
            <Route index element={<Navigate to=":planId" replace />} />
            <Route path=":planId" element={lazyView(<PlanSetCode />)} />
          </Route>
          <Route path="*" element={lazyView(<NotFound />)} />
        </Route>
        <Route
          path={`/${ROUTE_PATH.allotmentSetting}/:poolId`}
          element={<AllotmentLayout />}
        >
          <Route index element={<Navigate to={ROUTE_PATH.setting} replace />} />
          <Route
            path={ROUTE_PATH.setting}
            element={lazyView(<AllotmentSetting />)}
            handle={{ breadcrumb: 'layouts.sidebar.allotmentSetting' }}
          />
          <Route
            path={ROUTE_PATH.segmentId}
            element={lazyView(<AllotmentSegmentId />)}
            handle={{ breadcrumb: 'layouts.sidebar.allotmentSegmentId' }}
          />
          <Route
            path={ROUTE_PATH.exception}
            element={lazyView(<AllotmentException />)}
            handle={{ breadcrumb: 'layouts.sidebar.allotmentException' }}
          />
          <Route
            path={ROUTE_PATH.calendar}
            element={lazyView(<AllotmentCalendar />)}
            handle={{ breadcrumb: 'layouts.sidebar.allotmentCalendar' }}
          />
          <Route
            path={ROUTE_PATH.otaGroup}
            element={lazyView(<AllotmentOtaGroup />)}
            handle={{ breadcrumb: 'layouts.sidebar.allotmentOtaGroup' }}
          />
          <Route
            path={ROUTE_PATH.backend}
            element={lazyView(<AllotmentBackend />)}
            handle={{ breadcrumb: 'layouts.sidebar.allotmentBackend' }}
          />
        </Route>
        <Route
          path={`/${ROUTE_PATH.backendSetting}/:poolId`}
          element={<BackendLayout />}
        >
          <Route
            index
            element={<Navigate to={ROUTE_PATH.inventory} replace />}
          />
          <Route
            path={ROUTE_PATH.inventory}
            element={lazyView(<BackendInventory />)}
            handle={{ breadcrumb: 'layouts.sidebar.backendInventory' }}
          />
          <Route
            path={ROUTE_PATH.calendar}
            element={lazyView(<BackendCalendar />)}
            handle={{ breadcrumb: 'layouts.sidebar.backendCalendar' }}
          />
        </Route>
      </Route>
      <Route path={`/${ROUTE_PATH.login}`} element={lazyView(<Login />)} />
      <Route
        path={`/${ROUTE_PATH.resetPassword}`}
        element={lazyView(<ResetPassword />)}
      />
      <Route path="*" element={lazyView(<NotFound />)} />
    </Route>
  )
);

export default router;
