import { action, computed, makeObservable, observable } from 'mobx';
import { adminApi } from '@link/gds-supplier/services';
import { Ota as IOta } from '@link/gds-supplier/services/types';

export class Ota {
  nextCursor = '';
  loading = false;
  @observable accessor otas: Array<IOta> = [];

  constructor() {
    makeObservable(this);
  }

  @action
  fetchOtas = async () => {
    const response = await adminApi.listOta({
      body: {
        cursor: this.nextCursor
      }
    });
    const { otas, nextCursor = '' } = response;
    if (otas) {
      this.addOtas(otas);
    }
    this.setCursor(nextCursor);
    return response;
  };

  @action
  fetchAllOtas = async () => {
    if (!this.loaded && !this.loading) {
      this.loading = true;
      const { nextCursor } = await this.fetchOtas();
      this.loading = false;
      if (nextCursor) {
        this.fetchAllOtas();
      }
    }
  };

  @action
  addOtas = (otas: Array<IOta>) => {
    this.otas = this.otas.concat(otas);
  };

  @action
  setCursor = (cursor: string) => {
    this.nextCursor = cursor;
  };

  @computed
  get otaMapping(): Record<string, string> {
    return Object.fromEntries(this.otas.map(ota => [ota.id, ota.name]));
  }

  @computed
  get loaded() {
    return this.otas.length > 0 && this.nextCursor === '';
  }
}

export default new Ota();
