import React from 'react';
import ReactDOM from 'react-dom/client';
import { StoreProvider, store } from '@link/gds-supplier/stores';
import '@link/gds-supplier/i18n';
import '@link/gds-supplier/styles/index.css';
import App from '@link/gds-supplier/App';

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <StoreProvider value={store}>
      <App />
    </StoreProvider>
  </React.StrictMode>
);
