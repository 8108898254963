import i18n from 'i18next';
import {
  DEFAULT_LOCALE,
  LOCALE_LIST
} from '@link/gds-supplier/constants/locale';
import { initReactI18next } from 'react-i18next';

import jaShare from '@link/gds-share/i18n/lang/ja';

import enUI from '@linktivity/link-ui/lang/en';
import jaUI from '@linktivity/link-ui/lang/ja';
import koUI from '@linktivity/link-ui/lang/ko';
import zhUI from '@linktivity/link-ui/lang/zh';
import ztUI from '@linktivity/link-ui/lang/zt';

import jaComp from './component/ja';

import jaText from './lang/ja';

import jaConst from './const/ja';

import jaViews from './views/ja';

declare module 'i18next' {
  interface CustomTypeOptions {
    returnNull: false;
  }
}

export const resources = {
  en: {
    translation: {
      ...enUI
    }
  },
  ja: {
    translation: {
      ...jaUI,
      ...jaText,
      const: jaConst,
      views: jaViews,
      component: jaComp,
      share: jaShare
    }
  },
  ko: {
    translation: {
      ...koUI
    }
  },
  zh: {
    translation: {
      ...zhUI
    }
  },
  zt: {
    translation: {
      ...ztUI
    }
  }
} as const;

i18n.use(initReactI18next).init({
  lng: DEFAULT_LOCALE,
  returnNull: false,
  resources
});

export function setLocale(locale: string): string {
  i18n.changeLanguage(locale);
  document.documentElement.setAttribute('lang', locale);
  localStorage.setItem('locale', locale);
  return locale;
}

function getLocaleParams() {
  const searchParams = new URLSearchParams(location.search);
  const lang = searchParams.get('lang') || '';
  if (LOCALE_LIST.includes(lang)) {
    return lang;
  }
}

(() => {
  const defaultLocale =
    getLocaleParams() || localStorage.getItem('locale') || DEFAULT_LOCALE;
  setLocale(defaultLocale);
})();

export default i18n;
