import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { useLocation } from 'react-router-dom';
import { useMemo } from 'react';
import { Menu } from '@linktivity/link-ui';
import { OrderIcon, SettingsIcon } from '@link/gds-supplier/assets/icons';
import { useStore } from '@link/gds-supplier/stores';
import { useDocumentTitle } from '@link/gds-supplier/hooks';
import { ROUTE_PATH } from '@link/gds-share/constants/path';
import styles from './sidebar.module.css';

const Sidebar = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { auth } = useStore();
  useDocumentTitle();
  const menuList = useMemo(() => {
    return [
      {
        label: t('layouts.sidebar.dashboard'),
        to: ROUTE_PATH.dashboard
      },
      {
        label: t('layouts.sidebar.activitiesManagement'),
        icon: OrderIcon,
        name: 'activities',
        children: [
          {
            label: t('layouts.sidebar.activities'),
            to: ROUTE_PATH.activities
          },
          ...(auth.isAdmin || auth.isSupplierAdmin
            ? [
                {
                  label: t('layouts.sidebar.inventory'),
                  to: ROUTE_PATH.allotmentSettings
                }
              ]
            : []),
          ...(auth.isAdmin
            ? [
                {
                  label: t('layouts.sidebar.backendInventory'),
                  to: ROUTE_PATH.backendSettings
                }
              ]
            : [])
        ]
      },
      {
        label: t('layouts.sidebar.settings'),
        icon: SettingsIcon,
        name: 'settings',
        children: [
          ...(auth.isPasswordAuth
            ? [
                {
                  label: t('views.password.changePassword'),
                  to: ROUTE_PATH.changePassword
                }
              ]
            : [])
        ]
      }
    ];
  }, [t, auth.isAdmin, auth.isSupplierAdmin, auth.isPasswordAuth]);
  const activeSubMenu = useMemo(() => {
    return menuList.find(menu => {
      if (menu.children) {
        return menu.children.some(child => {
          return pathname.indexOf(child.to) > -1;
        });
      }
    });
  }, [menuList, pathname]);

  return (
    <aside className={styles.sidebar}>
      <div className={styles.inner}>
        {auth.isClaimsLoaded && (
          <Menu
            key={pathname}
            className={styles.menu}
            accordion
            defaultExpandedMenu={[activeSubMenu?.name || '']}
          >
            {menuList.map(menu => {
              const { label, icon, name, children, to } = menu;
              if (children) {
                return (
                  <Menu.SubMenu
                    key={label}
                    label={label}
                    Icon={icon}
                    name={name}
                  >
                    {children.map(child => {
                      const { label, to } = child;
                      return (
                        <Menu.MenuItem key={label} label={label} to={to} />
                      );
                    })}
                  </Menu.SubMenu>
                );
              }
              return <Menu.MenuItem key={label} label={label} to={to} />;
            })}
          </Menu>
        )}
      </div>
    </aside>
  );
};
const ObserverSidebar = observer(Sidebar);
export default ObserverSidebar;
