import { useState, useMemo, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { Dropdown, message } from '@linktivity/link-ui';
import { User } from '@linktivity/link-icons';
import supplierAuth from '@link/gds-supplier/vendors/firebase';
import { useStore } from '@link/gds-supplier/stores';
import styles from './header.module.css';

const UserView = () => {
  const { auth, supplier } = useStore();
  const { t } = useTranslation();
  const [openMenu, setOpenMenu] = useState(false);

  useEffect(() => {
    if (auth.isSupplier) {
      supplier.getSupplierInfo();
    }
  }, [auth.isSupplier, supplier]);

  const userButton = useMemo(
    () => (
      <button
        className={styles.button}
        type="button"
        onClick={() => setOpenMenu(visible => !visible)}
      >
        <User className={styles.icon} />
        <span className={styles.text}>{t('common.account')}</span>
      </button>
    ),
    [t]
  );

  const showMessage = useCallback((msg: string) => {
    message.info(msg, 10);
  }, []);

  const logout = useCallback(() => {
    supplierAuth.logout();
  }, []);

  return (
    <Dropdown
      target={userButton}
      visible={openMenu}
      sameWidth={false}
      placement="bottom-end"
      onClose={() => setOpenMenu(false)}
    >
      <Dropdown.Menu className={styles.dropdownMenu}>
        {supplier.supplierName && (
          <Dropdown.Item>
            <button
              className={styles.dropdownButton}
              onClick={() => showMessage(supplier.supplierName)}
            >
              <span className={styles.dropdownText}>
                {supplier.supplierName}
              </span>
            </button>
          </Dropdown.Item>
        )}
        <Dropdown.Item>
          <button
            className={styles.dropdownButton}
            onClick={() => showMessage(auth.email)}
          >
            <span className={styles.dropdownText}>{auth.email}</span>
          </button>
        </Dropdown.Item>
        <Dropdown.Item>
          <button className={styles.dropdownButton} onClick={logout}>
            {t('common.signOut')}
          </button>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};
const ObserverUserView = observer(UserView);
export default ObserverUserView;
