export const ROUTE_PATH = {
  changePassword: 'change-password',
  resetPassword: 'reset-password',
  activities: 'activities',
  dashboard: 'dashboard',
  allotmentSettings: 'allotment-settings',
  backendSettings: 'backend-settings',
  activity: 'activity',
  allotmentSetting: 'allotment-setting',
  backendSetting: 'backend-setting',
  info: 'info',
  detail: 'detail',
  agent: 'agent',
  i18n: 'i18n',
  booking: 'booking',
  price: 'price',
  plan: 'plan',
  memo: 'memo',
  editLog: 'edit-log',
  admin: 'admin',
  login: 'login',
  setting: 'setting',
  segmentId: 'segmentId',
  exception: 'exception',
  calendar: 'calendar',
  otaGroup: 'ota-group',
  backend: 'backend',
  inventory: 'inventory',
  backendCalendar: 'calendar'
};
