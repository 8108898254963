import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
// import cls from 'clsx';
// import { Button, Select, Modal } from '@linktivity/gds-frontend-design';
// import { SelectValueType } from '@linktivity/gds-frontend-design/components/Select';
import { useStore } from '@link/gds-supplier/stores';
// import styles from './header.module.css';

const SupplierView = () => {
  const { auth, supplier } = useStore();
  // const [visible, setVisible] = useState(false);
  // const [supplierId, setSupplierId] = useState<SelectValueType>(
  //   supplier.currentSupplierId
  // );

  useEffect(() => {
    if (auth.isAdmin) {
      supplier.fetchAllSuppliers();
    }
  }, [auth.isAdmin, supplier]);

  // const selectSupplier = useCallback(() => {
  //   supplier.setSupplierId(supplierId as string);
  //   setVisible(false);
  // }, [supplier, supplierId]);

  // if (auth.isAdmin) {
  //   return (
  //     <div className={styles.supplier}>
  //       <button
  //         type="button"
  //         className={cls(styles.button, styles.supplierButton)}
  //         title={supplier.currentSupplier?.name}
  //         onClick={() => setVisible(true)}
  //       >
  //         {supplier.currentSupplierId || 'Select supplier'}
  //       </button>
  //       <Modal visible={visible} title={'Select supplier'} onClose={setVisible}>
  //         <div className={styles.supplierContent}>
  //           <Select
  //             options={supplier.supplierOptions}
  //             value={supplierId}
  //             onSelect={setSupplierId}
  //             withinPortal={false}
  //             className={styles.select}
  //             virtualScroll
  //             filterable
  //           />
  //         </div>
  //         <div className={styles.supplierFooter}>
  //           <Button color="standard" onClick={() => setVisible(false)}>
  //             {'close'}
  //           </Button>
  //           <Button color="primary" onClick={selectSupplier}>
  //             {'confirm'}
  //           </Button>
  //         </div>
  //       </Modal>
  //     </div>
  //   );
  // } else {
  //   return null;
  // }
  return null;
};
const ObserverSupplierView = observer(SupplierView);
export default ObserverSupplierView;
