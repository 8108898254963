import { Outlet, Navigate, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useStore } from '@link/gds-supplier/stores';

const AdminRoute = () => {
  const { auth } = useStore();
  const location = useLocation();

  if (auth.isAdmin) {
    return <Outlet />;
  } else {
    return <Navigate to="/dashboard" state={{ from: location }} replace />;
  }
};
const ObserverAdminRoute = observer(AdminRoute);
export default ObserverAdminRoute;
