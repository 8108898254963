import { Activityeditor, Allotment } from '@linktivity/link-connect';

const ActivityCategory = Activityeditor.ActivityCategory;
type ActivityCategory = Activityeditor.ActivityCategory;

const TemplateStatus = Activityeditor.TemplateStatus;
type TemplateStatus = Activityeditor.TemplateStatus;

const SalesStatus = Activityeditor.SalesStatus;
type SalesStatus = Activityeditor.SalesStatus;

const VoucherType = Activityeditor.VoucherType;
type VoucherType = Activityeditor.VoucherType;

const QRCodeType = Activityeditor.QRCodeType;
type QRCodeType = Activityeditor.QRCodeType;

const VoucherMedium = Activityeditor.VoucherMedium;
type VoucherMedium = Activityeditor.VoucherMedium;

const CancellationSpecApplyTiming = Activityeditor.CancellationSpecApplyTiming;
type CancellationSpecApplyTiming = Activityeditor.CancellationSpecApplyTiming;

const FixedFeeCollectingType = Activityeditor.FixedFeeCollectingType;
type FixedFeeCollectingType = Activityeditor.FixedFeeCollectingType;

const BasePrice = Activityeditor.Base;
type BasePrice = Activityeditor.Base;

const ConfirmationType = Activityeditor.ConfirmationType;
type ConfirmationType = Activityeditor.ConfirmationType;

const DatePeriodUnit = Activityeditor.DatePeriodUnit;
type DatePeriodUnit = Activityeditor.DatePeriodUnit;

const UnitType = Activityeditor.UnitType;
type UnitType = Activityeditor.UnitType;

const DayOfWeek = Activityeditor.DayOfWeek;
type DayOfWeek = Activityeditor.DayOfWeek;

const ChecklistType = Activityeditor.ChecklistType;
type ChecklistType = Activityeditor.ChecklistType;

const BookingFieldId = Activityeditor.BookingFieldId;
type BookingFieldId = Activityeditor.BookingFieldId;

const ExtendedBookingFieldId = Activityeditor.ExtendedBookingFieldId;
type ExtendedBookingFieldId = Activityeditor.ExtendedBookingFieldId;

const ExtendedParticipantFieldId = Activityeditor.ExtendedParticipantFieldId;
type ExtendedParticipantFieldId = Activityeditor.ExtendedParticipantFieldId;

const AdditionalFormCollectingType =
  Activityeditor.AdditionalFormCollectingType;
type AdditionalFormCollectingType = Activityeditor.AdditionalFormCollectingType;

const CollectingTiming = Activityeditor.CollectingTiming;
type CollectingTiming = Activityeditor.CollectingTiming;

const FormType = Activityeditor.FormType;
type FormType = Activityeditor.FormType;

const Operation = Activityeditor.CommonOperation;
type Operation = Activityeditor.CommonOperation;

const TaskStatus = Activityeditor.TaskStatus;
type TaskStatus = Activityeditor.TaskStatus;

const JobState = Allotment.JobState;
type JobState = Allotment.JobState;

const PaymentCollector = Activityeditor.PaymentCollector;
type PaymentCollector = Activityeditor.PaymentCollector;

const VISIBILITY_SETTING_TYPES = [
  'hideFromVoucher',
  'hideFromActivityDetails',
  'hideFromBookingDetails'
] as const;
type VISIBILITY_SETTING_TYPES = (typeof VISIBILITY_SETTING_TYPES)[number];

const LOG_TYPE = {
  ACTIVITY: 'ACTIVITY',
  PRICE: 'PRICE',
  PLAN: 'PLAN'
};

const ALLOTMENT_SETTING_FORM = {
  FREESALE: 'freesale',
  COPY_CALENDAR_EDITS: 'copyCalendarEdits'
};

export {
  ActivityCategory,
  TemplateStatus,
  SalesStatus,
  VoucherType,
  QRCodeType,
  VoucherMedium,
  CancellationSpecApplyTiming,
  FixedFeeCollectingType,
  BasePrice,
  ConfirmationType,
  DatePeriodUnit,
  UnitType,
  DayOfWeek,
  ChecklistType,
  BookingFieldId,
  ExtendedBookingFieldId,
  ExtendedParticipantFieldId,
  AdditionalFormCollectingType,
  CollectingTiming,
  FormType,
  Operation,
  TaskStatus,
  JobState,
  PaymentCollector,
  VISIBILITY_SETTING_TYPES,
  LOG_TYPE,
  ALLOTMENT_SETTING_FORM
};
