import { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from '@link/gds-supplier/stores';
import { useMatches, useParams } from 'react-router-dom';

interface Store {
  title?: string;
  name?: string;
}
export const useBreadcrumb = (store: Store) => {
  const { t } = useTranslation();
  const matches = useMatches();
  const { breadcrumb } = useStore();
  const { activityId, planId, poolId } = useParams();

  const breadcrumbItems = useMemo(() => {
    return matches
      .filter(match => Boolean(match.pathname !== '/'))
      .map(match => {
        const handle = match.handle || {};
        const { breadcrumb } = handle as {
          breadcrumb?: string;
        };
        const { pathname } = match;
        const lastPath = pathname.split('/').pop();
        const name =
          lastPath === activityId
            ? store.title
            : lastPath === planId
              ? t('layouts.sidebar.adminPlanInfo', { title: store.title })
              : lastPath === poolId
                ? store.name
                : t(breadcrumb || '');

        return {
          to: pathname,
          name
        };
      });
  }, [matches, t, activityId, planId, poolId, store?.title, store?.name]);

  const setBreadcrumbRoutes = useCallback(
    (additionalRoutes: { to: string; name: string }[]) => {
      const routes = [...additionalRoutes, ...breadcrumbItems];
      breadcrumb.setRoutes(routes);
    },
    [breadcrumb, breadcrumbItems]
  );

  return { setBreadcrumbRoutes };
};
