import React, { useEffect } from 'react';
import { useParams, Outlet } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Loader } from '@link/gds-share/components';
import { useStore } from '@link/gds-supplier/stores';

const PlanLayout: React.FC = () => {
  const { activity, plan } = useStore();
  const { planId } = useParams();
  useEffect(() => {
    if (planId) {
      const currentPlan = activity.plans.find(plan => plan.id === planId);
      if (currentPlan) {
        plan.setPlan(currentPlan);
      }
    }
  }, [planId, activity, plan]);
  return <>{plan.loaded ? <Outlet key={plan.planId} /> : <Loader />}</>;
};
const ObserverPlanLayout = observer(PlanLayout);
export default ObserverPlanLayout;
