export default {
  common: {
    account: 'アカウント',
    signOut: 'サインアウト',
    no: 'いいえ',
    yes: 'はい',
    add: '追加',
    or: 'または',
    edit: '編集',
    register: '登録',
    save: '保存',
    on: 'オン',
    off: 'オフ',
    cancel: 'キャンセル',
    confirm: '確定',
    colon: ':'
  },
  lang: {
    en: '英語',
    ja: '日本語',
    zh: '中国語(簡体)',
    zt: '中国語(繁体)',
    ko: '韓国語'
  },
  layouts: {
    sidebar: {
      dashboard: 'ダッシュボード',
      activitiesManagement: '商品管理',
      activities: '商品一覧',
      settings: '設定',
      info: '基本情報',
      detail: '詳細情報',
      agent: '価格グループ設定',
      i18n: '多言語設定',
      planInfo: 'プラン - {{title}}',
      booking: '予約情報設定',
      price: 'ユニット/料金設定',
      addPlan: 'プラン追加',
      planName: 'プラン名',
      duplicatePlan: 'プラン複製',
      adminSetting: 'Admin設定',
      adminPlanInfo: 'プラン - {{title}} 設定',
      adminActivity: '商品設定',
      adminPlan: 'プラン設定',
      inventory: '在庫設定',
      backendInventory: 'Backend在庫設定',
      editMemo: 'メモ編集',
      editLog: '編集履歴',
      allotmentSetting: '営業日・在庫設定',
      allotmentSegmentId: '開始時間ID追加',
      allotmentException: '例外開始時間設定',
      allotmentCalendar: 'カレンダー編集',
      allotmentOtaGroup: '代理店在庫グループ設定',
      allotmentBackend: 'Backend設定',
      backendCalendar: 'カレンダー'
    }
  },
  firebase: {
    'auth/wrong-password': 'ログイン名またはパスワードが間違っています。',
    'auth/user-not-found': 'ログイン名またはパスワードが間違っています。',
    'auth/email-already-in-use': 'メールアドレスは既に登録されています。',
    'auth/too-many-requests':
      'ログイン試行の回数が上限を超えました。しばらくしてからもう一度お試しいただくか、パスワードをリセットしてください。'
  },
  validation: {
    required: '{{name}}を入力してください',
    requiredSelect: '選択してください',
    invalid: '有効な{{name}}を入力してください',
    password: '文字と数字を組み合わせた8文字以上で作成ください'
  }
};
