/* eslint-disable @typescript-eslint/no-explicit-any */
import { initializeApp, FirebaseApp, FirebaseOptions } from 'firebase/app';
import {
  FirebaseStorage,
  getStorage,
  ref,
  deleteObject,
  uploadBytes,
  getDownloadURL
} from 'firebase/storage';
import {
  initializeAuth,
  browserLocalPersistence,
  browserPopupRedirectResolver,
  Auth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut,
  GoogleAuthProvider,
  FacebookAuthProvider,
  reauthenticateWithPopup,
  signInWithPopup,
  EmailAuthProvider,
  updatePassword,
  sendPasswordResetEmail,
  deleteUser,
  reauthenticateWithCredential
} from 'firebase/auth';

export type { User, UserCredential } from 'firebase/auth';
export const FIREBASE_LANG_MAPPING: Record<string, string> = {
  en: 'en',
  ja: 'ja',
  ko: 'ko',
  zh: 'zh_cn',
  zt: 'zh_tw'
};

export interface Claims {
  role: string;
  supplier: string;
}

export class FirebaseAuth {
  app: FirebaseApp;
  auth: Auth;
  storage: FirebaseStorage;
  constructor(options: FirebaseOptions) {
    this.app = initializeApp(options);
    this.storage = getStorage(this.app);
    this.auth = initializeAuth(this.app, {
      persistence: [browserLocalPersistence],
      popupRedirectResolver: browserPopupRedirectResolver
    });
  }

  uploadFile(file: File, path: string) {
    const storageRef = ref(this.storage, path);
    return uploadBytes(storageRef, file).catch(error => {
      this.showErrorMessage(error);
    });
  }

  deleteFile(path: string) {
    const storageRef = ref(this.storage, path);
    return deleteObject(storageRef).catch(error => {
      this.showErrorMessage(error);
    });
  }

  downloadFile(path: string) {
    const storageRef = ref(this.storage, path);
    return getDownloadURL(storageRef);
  }

  updateLangCode(locale: string) {
    this.auth.languageCode = FIREBASE_LANG_MAPPING[locale];
  }

  setTenantId(id: string) {
    this.auth.tenantId = id;
  }

  setApiHost(host: string) {
    this.auth.config.apiHost = host;
  }

  showErrorMessage(error: any) {
    console.log(error.code, error.message);
    throw new Error('subclass should implement this method!');
  }

  async login(email: string, password: string) {
    try {
      return await signInWithEmailAndPassword(this.auth, email, password);
    } catch (error) {
      this.showErrorMessage(error);
    }
  }

  async getClaims() {
    const tokenResult = await this.auth.currentUser?.getIdTokenResult();
    if (tokenResult) {
      return tokenResult.claims as Claims;
    }
  }

  async checkUserExists(email: string, password = 'password') {
    try {
      await signInWithEmailAndPassword(this.auth, email, password);
      return true;
    } catch (error: any) {
      if (error.code === 'auth/user-not-found') {
        return false;
      } else {
        return true;
      }
    }
  }

  async register(email: string, password: string) {
    return createUserWithEmailAndPassword(this.auth, email, password).catch(
      error => {
        this.showErrorMessage(error);
      }
    );
  }

  async logout() {
    return await signOut(this.auth);
  }

  async googleLogin() {
    const provider = new GoogleAuthProvider();
    provider.setCustomParameters({
      prompt: 'select_account'
    });
    return signInWithPopup(this.auth, provider).catch(error => {
      this.showErrorMessage(error);
    });
  }

  async facebookLogin() {
    const provider = new FacebookAuthProvider();
    signInWithPopup(this.auth, provider).catch(error => {
      this.showErrorMessage(error);
    });
  }

  async reauthWithPassword(password: string) {
    const user = this.auth.currentUser;
    if (user) {
      const email = user.email;
      if (email) {
        const credential = EmailAuthProvider.credential(email, password);
        return reauthenticateWithCredential(user, credential)
          .then(() => {
            return true;
          })
          .catch(error => {
            this.showErrorMessage(error);
          });
      }
    }
  }

  async reauthWithGoogle() {
    const user = this.auth.currentUser;
    if (user) {
      const provider = new GoogleAuthProvider();
      return reauthenticateWithPopup(user, provider)
        .then(() => {
          return true;
        })
        .catch(error => {
          this.showErrorMessage(error);
        });
    }
  }

  async reauthWithFacebook() {
    const user = this.auth.currentUser;
    if (user) {
      const provider = new FacebookAuthProvider();
      return reauthenticateWithPopup(user, provider)
        .then(() => {
          return true;
        })
        .catch(error => {
          this.showErrorMessage(error);
        });
    }
  }

  async changePassword(oldPassword: string, newPassword: string) {
    const user = this.auth.currentUser;
    if (user) {
      const auth = await this.reauthWithPassword(oldPassword);
      if (auth) {
        return updatePassword(user, newPassword)
          .then(() => {
            return true;
          })
          .catch(error => {
            this.showErrorMessage(error);
          });
      }
    }
  }

  async sendResetEmail(email: string, locale = 'en') {
    this.updateLangCode(locale);
    return sendPasswordResetEmail(this.auth, email)
      .then(() => {
        return true;
      })
      .catch(error => {
        this.showErrorMessage(error);
      });
  }

  async deleteAccount() {
    const user = this.auth.currentUser;
    if (user) {
      return deleteUser(user)
        .then(() => {
          return true;
        })
        .catch(error => {
          this.showErrorMessage(error);
        });
    }
  }
}
