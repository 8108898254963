import { useEffect } from 'react';
import { useParams, Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { Loader } from '@link/gds-share/components';
import { ROUTE_PATH } from '@link/gds-share/constants/path';
import { useBreadcrumb } from '@link/gds-supplier/hooks';
import { useStore } from '@link/gds-supplier/stores';
import Header from '../Header';
import Sidebar from './Sidebar';
import styles from './allotment.module.css';

const AllotmentLayout = () => {
  const { t } = useTranslation();
  const { allotment, breadcrumb } = useStore();
  const { setBreadcrumbRoutes } = useBreadcrumb(allotment);
  const { poolId } = useParams();

  useEffect(() => {
    if (allotment.name) {
      const routes = [
        {
          to: `/${ROUTE_PATH.allotmentSettings}`,
          name: t('layouts.sidebar.allotmentSetting')
        }
      ];
      setBreadcrumbRoutes(routes);
    }

    return () => {
      breadcrumb.clear();
    };
  }, [allotment.name, breadcrumb, t, setBreadcrumbRoutes]);

  useEffect(() => {
    if (poolId) {
      allotment.getAlloment(poolId);
    }
    return () => {
      allotment.clearAlloment();
    };
  }, [allotment, poolId]);

  return (
    <div className={styles.allotment}>
      <Header />
      <div className={styles.container}>
        <Sidebar />
        <main className={styles.main}>
          <div className={styles.inner}>
            {allotment.loaded ? <Outlet /> : <Loader />}
          </div>
        </main>
      </div>
    </div>
  );
};
const ObserverAllotmentLayout = observer(AllotmentLayout);
export default ObserverAllotmentLayout;
