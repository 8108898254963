import { uuid } from '@link/gds-share/utils';

export interface WithUUID {
  uuid?: string;
}

export function addUUID<T>(obj: T): T & WithUUID {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (!obj.uuid) {
    Object.defineProperty(obj, 'uuid', {
      value: uuid()
    });
  }
  return obj as T & WithUUID;
}

export function addUUIDs<T>(arr?: T[]) {
  return arr?.map(item => addUUID(item));
}
