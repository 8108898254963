import { useCallback, useState, useMemo, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import cls from 'clsx';
import { useLocation, useParams } from 'react-router-dom';
import {
  Button,
  Dialog,
  Form,
  Input,
  Select,
  Menu,
  Switch,
  FormValues,
  Rules,
  SelectOptionType
} from '@linktivity/link-ui';
import { useStore } from '@link/gds-supplier/stores';
import { SalesStatus } from '@link/gds-supplier/services/const';
import { EyeOff } from '@linktivity/link-icons';
import { LogIcon, MemoIcon } from '@link/gds-supplier/assets/icons';
import { useDocumentTitle } from '@link/gds-supplier/hooks';
import { ROUTE_PATH } from '@link/gds-share/constants/path';

import styles from './sidebar.module.css';

const Sidebar = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { planId = '', activityId = '' } = useParams();
  const { activity, auth } = useStore();
  const [adding, setAdding] = useState(false);
  const [visible, setVisible] = useState(false);
  const [duplicateMode, setDuplicateMode] = useState(false);
  useDocumentTitle();

  const [valid, setValid] = useState(true);

  const initFormValues = useMemo(() => ({ title: '', sourcePlanId: '' }), []);
  const [formValues, setFormValues] = useState<FormValues>(initFormValues);

  const availablePlans = useMemo<SelectOptionType[]>(
    () =>
      activity.plans.map(plan => ({
        label: plan.title,
        value: plan.id || ''
      })) || [],
    [activity.plans]
  );

  const rules: Rules = useMemo(
    () => ({
      title: [
        {
          required: true,
          message: t('validation.required', {
            name: t('layouts.sidebar.planName')
          }),
          trigger: ['change', 'blur']
        }
      ],
      sourcePlanId: [
        {
          required: duplicateMode,
          message: t('validation.requiredSelect'),
          trigger: ['change', 'blur']
        }
      ]
    }),
    [duplicateMode, t]
  );

  useEffect(() => {
    if (duplicateMode) {
      const { sourcePlanId, title } = formValues;
      sourcePlanId && title ? setValid(true) : setValid(false);
    } else {
      const { title } = formValues;
      title.length ? setValid(true) : setValid(false);
    }
  }, [duplicateMode, formValues]);

  const submitForm = useCallback(async (): Promise<void> => {
    const { title, sourcePlanId } = formValues;
    setAdding(true);
    try {
      duplicateMode
        ? await activity.duplicatePlan(title, sourcePlanId)
        : await activity.createPlan(title);
    } finally {
      setVisible(false);
      setAdding(false);
    }
  }, [formValues, duplicateMode, activity]);

  const openForm = useCallback(() => {
    setVisible(true);
    setFormValues(initFormValues);
  }, [initFormValues]);

  const sortedPlans = useMemo(
    () =>
      activity.plans.sort(
        (planA, planB) =>
          (planB.salesStatus === SalesStatus.On ? 1 : 0) -
          (planA.salesStatus === SalesStatus.On ? 1 : 0)
      ),
    [activity.plans]
  );

  const planStatusIcon = useCallback(
    (status: SalesStatus | undefined) =>
      status === SalesStatus.Off ? EyeOff : undefined,
    []
  );
  const isPlanPath = useMemo(
    () => pathname.includes(`/${activityId}/${planId}`),
    [activityId, planId, pathname]
  );
  const isAdminPath = useMemo(
    () => pathname.includes(ROUTE_PATH.admin),
    [pathname]
  );
  return (
    <aside className={styles.sidebar}>
      <div className={styles.nav}>
        {auth.isClaimsLoaded && activity.loaded && (
          <div className={styles.inner}>
            <Menu
              className={styles.menu}
              accordion
              defaultExpandedMenu={isPlanPath ? [planId] : []}
            >
              <Menu.MenuItem
                label={t('layouts.sidebar.info')}
                to={ROUTE_PATH.info}
              />
              <Menu.MenuItem
                label={t('layouts.sidebar.detail')}
                to={ROUTE_PATH.detail}
              />
              <Menu.MenuItem
                label={t('layouts.sidebar.agent')}
                to={ROUTE_PATH.agent}
              />
              <Menu.MenuItem
                label={t('layouts.sidebar.i18n')}
                to={ROUTE_PATH.i18n}
              />
              {sortedPlans.map(plan => (
                <Menu.SubMenu
                  label={t('layouts.sidebar.planInfo', {
                    title: plan.title,
                    interpolation: { escapeValue: false }
                  })}
                  key={plan.id}
                  Icon={planStatusIcon(plan.salesStatus)}
                  name={plan.id}
                  className={cls(styles.menuSub, {
                    [styles.archived]: plan.salesStatus === SalesStatus.Off
                  })}
                >
                  <Menu.MenuItem
                    label={t('layouts.sidebar.info')}
                    to={`${plan.id}/${ROUTE_PATH.info}`}
                  />
                  <Menu.MenuItem
                    label={t('layouts.sidebar.detail')}
                    to={`${plan.id}/${ROUTE_PATH.detail}`}
                  />
                  <Menu.MenuItem
                    label={t('layouts.sidebar.booking')}
                    to={`${plan.id}/${ROUTE_PATH.booking}`}
                  />
                  <Menu.MenuItem
                    label={t('layouts.sidebar.i18n')}
                    to={`${plan.id}/${ROUTE_PATH.i18n}`}
                  />
                  <Menu.MenuItem
                    label={t('layouts.sidebar.price')}
                    to={`${plan.id}/${ROUTE_PATH.price}`}
                  />
                </Menu.SubMenu>
              ))}
            </Menu>
            <div className={styles.addPlan}>
              <Button variant="outlined" size="medium" onClick={openForm}>
                {t('layouts.sidebar.addPlan')}
              </Button>
            </div>
            {auth.isAdmin && (
              <Menu
                className={styles.menu}
                accordion
                defaultExpandedMenu={isAdminPath ? [ROUTE_PATH.admin] : []}
              >
                <Menu.SubMenu
                  label={t('layouts.sidebar.adminSetting')}
                  name={ROUTE_PATH.admin}
                >
                  <Menu.MenuItem
                    label={t('layouts.sidebar.adminActivity')}
                    to={`admin/${ROUTE_PATH.activity}`}
                  />
                  {sortedPlans.map(plan => (
                    <Menu.MenuItem
                      label={t('layouts.sidebar.adminPlanInfo', {
                        title: plan.title,
                        interpolation: { escapeValue: false }
                      })}
                      key={plan.id}
                      Icon={planStatusIcon(plan.salesStatus)}
                      className={cls(styles.menuItem, {
                        [styles.archived]: plan.salesStatus === SalesStatus.Off
                      })}
                      to={`${ROUTE_PATH.admin}/${plan.id}`}
                    />
                  ))}
                </Menu.SubMenu>
              </Menu>
            )}
            <Dialog
              visible={visible}
              title={t('layouts.sidebar.addPlan')}
              onClose={() => setVisible(false)}
              className={styles.new}
              footer={
                <div className={styles.newFooter}>
                  <Button
                    type="submit"
                    form="newPlan"
                    disabled={!valid}
                    loading={adding}
                  >
                    {duplicateMode
                      ? t('layouts.sidebar.duplicatePlan')
                      : t('layouts.sidebar.addPlan')}
                  </Button>
                </div>
              }
            >
              <div className={styles.newBody}>
                <Form
                  id="newPlan"
                  values={formValues}
                  onValuesChange={setFormValues}
                  onValidateChange={setValid}
                  rules={rules}
                  onSubmit={submitForm}
                >
                  <Form.Item
                    label={t('layouts.sidebar.planName')}
                    field="title"
                  >
                    <Input clearable />
                  </Form.Item>
                  {!!availablePlans.length && (
                    <div className={styles.newBodySwitch}>
                      {t('layouts.sidebar.duplicatePlan')}
                      <Switch
                        checked={duplicateMode}
                        onChange={({ currentTarget }) =>
                          setDuplicateMode(currentTarget.checked)
                        }
                      />
                    </div>
                  )}
                  {duplicateMode && (
                    <Form.Item field="sourcePlanId">
                      <Select
                        className={styles.newBodySelect}
                        options={availablePlans}
                        withinPortal={false}
                      />
                    </Form.Item>
                  )}
                </Form>
              </div>
            </Dialog>
          </div>
        )}
      </div>
      <div className={styles.bottom}>
        <Menu className={styles.menu}>
          <Menu.MenuItem
            label={t('layouts.sidebar.editMemo')}
            Icon={MemoIcon}
            to={ROUTE_PATH.memo}
          />
          <Menu.MenuItem
            label={t('layouts.sidebar.editLog')}
            Icon={LogIcon}
            to={ROUTE_PATH.editLog}
          />
        </Menu>
      </div>
    </aside>
  );
};
const ObserverSidebar = observer(Sidebar);
export default ObserverSidebar;
