export const DEFAULT_LOCALE = 'ja';
export const LOCALE_LIST = ['en', 'ja', 'ko', 'zh', 'zt'];

export const LOCALE_LANG_MAPPING: Record<string, string> = {
  en: 'en-us',
  ja: 'ja-jp',
  ko: 'ko-kr',
  zh: 'zh-hans',
  zt: 'zh-hant'
};

export const LANG_LOCALE_MAPPING: Record<string, string> = Object.fromEntries(
  Object.entries(LOCALE_LANG_MAPPING).map(locale => locale.reverse())
);
