import { FirebaseAuth } from '@link/gds-share/utils';
import i18n from '@link/gds-supplier/i18n';
import { message } from '@linktivity/link-ui';
import { auth } from '@link/gds-supplier/stores';

export type { User, Claims, UserCredential } from '@link/gds-share/utils';

const firebaseConfig = {
  apiKey: import.meta.env.VITE_APP_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_APP_FIREBASE_AUTH_DOMAIN,
  storageBucket: import.meta.env.VITE_APP_FIREBAE_STORAGE_BUCKET
};

const tenantId = import.meta.env.VITE_APP_FIREBASE_TENANT_ID;

class SupplierAuth extends FirebaseAuth {
  constructor() {
    super(firebaseConfig);
    this.setTenantId(tenantId);

    this.auth.onAuthStateChanged(user => {
      if (user) {
        auth.setUser(user);
      } else {
        auth.clearUser();
      }
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  showErrorMessage(error: any) {
    const errorCode = error.code;
    let errorMessage = error.message;
    if (i18n.exists(`firebase.${errorCode}`)) {
      errorMessage = i18n.t(`firebase.${errorCode}`);
    }
    message.error(errorMessage);
  }
}

const supplierAuth = new SupplierAuth();

export const storage = supplierAuth.storage;
export default supplierAuth;
