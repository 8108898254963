import { useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import { ROUTE_PATH } from '@link/gds-share/constants/path';
import { useStore } from '../stores';

const DEFAULT_TITLE = 'GDS Supplier';

export const useDocumentTitle = () => {
  const { t } = useTranslation();
  const { activity, allotment, inventory } = useStore();
  const { pathname } = useLocation();
  const { planId } = useParams();
  const splittedPath = pathname.replace('/', '').split('/');
  const [urlRoute] = splittedPath;
  const urlSubRoute = splittedPath.pop() || '';

  const titles: Record<string, string> = useMemo(
    () => ({
      [ROUTE_PATH.activities]: t('views.activities.list'),
      [ROUTE_PATH.allotmentSettings]: t('views.allotmentSettings.title'),
      [ROUTE_PATH.backendSettings]: t('views.backendSettings.title'),
      [ROUTE_PATH.activity]: activity.title || t('views.activity.name'),
      [ROUTE_PATH.allotmentSetting]:
        allotment.name || t('views.allotmentSettings.name'),
      [ROUTE_PATH.backendSetting]:
        inventory.name || t('views.backendSettings.name'),
      [ROUTE_PATH.login]: t('views.login.title')
    }),
    [activity.title, t, allotment.name, inventory.name]
  );

  const subRouteTitles: Record<string, string> = useMemo(
    () => ({
      [ROUTE_PATH.info]: t('layouts.sidebar.info'),
      [ROUTE_PATH.detail]: t('layouts.sidebar.detail'),
      [ROUTE_PATH.agent]: t('layouts.sidebar.agent'),
      [ROUTE_PATH.i18n]: t('layouts.sidebar.i18n'),
      [ROUTE_PATH.booking]: t('layouts.sidebar.booking'),
      [ROUTE_PATH.price]: t('layouts.sidebar.price'),
      [ROUTE_PATH.activity]: t('layouts.sidebar.adminActivity'),
      [ROUTE_PATH.plan]: t('layouts.sidebar.adminPlanInfo'),
      [ROUTE_PATH.memo]: t('layouts.sidebar.editMemo'),
      [ROUTE_PATH.setting]: t('layouts.sidebar.allotmentSetting'),
      [ROUTE_PATH.segmentId]: t('layouts.sidebar.allotmentSegmentId'),
      [ROUTE_PATH.exception]: t('layouts.sidebar.allotmentException'),
      [ROUTE_PATH.calendar]: t('layouts.sidebar.allotmentCalendar'),
      [ROUTE_PATH.otaGroup]: t('layouts.sidebar.allotmentOtaGroup'),
      [ROUTE_PATH.backend]: t('layouts.sidebar.allotmentBackend'),
      [ROUTE_PATH.inventory]: t('layouts.sidebar.backendInventory'),
      [ROUTE_PATH.backendCalendar]: t('layouts.sidebar.backendCalendar'),
      [ROUTE_PATH.editLog]: t('layouts.sidebar.editLog'),
      [ROUTE_PATH.dashboard]: t('layouts.sidebar.dashboard'),
      [ROUTE_PATH.changePassword]: t('views.password.changePassword'),
      [ROUTE_PATH.resetPassword]: t('views.password.resetPassword')
    }),
    [t]
  );

  const planTitleMemo = useMemo(() => {
    if (planId) {
      const planIndex = activity.plans.findIndex(plan => plan.id === planId);
      return planIndex > -1
        ? t('layouts.sidebar.planInfo', {
            title: activity.plans[planIndex].title,
            interpolation: { escapeValue: false }
          }) + ' - '
        : '';
    }
    return '';
  }, [activity.plans, planId, t]);

  const adminTitles: string = useMemo(
    () =>
      splittedPath.includes(ROUTE_PATH.admin)
        ? t('layouts.sidebar.adminSetting')
        : '',
    [splittedPath, t]
  );

  useEffect(() => {
    document.title =
      `${adminTitles ? adminTitles + ' - ' : ''}
      ${planTitleMemo}${
        subRouteTitles[urlSubRoute] ? subRouteTitles[urlSubRoute] + ' - ' : ''
      }${titles[urlRoute] ? titles[urlRoute] + ' - ' : ''}` + DEFAULT_TITLE;
  }, [
    adminTitles,
    planTitleMemo,
    subRouteTitles,
    titles,
    urlRoute,
    urlSubRoute
  ]);
};
