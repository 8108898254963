import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { Menu } from '@linktivity/link-ui';
import { ROUTE_PATH } from '@link/gds-share/constants/path';

import styles from './sidebar.module.css';

const Sidebar = () => {
  const { t } = useTranslation();

  return (
    <aside className={styles.sidebar}>
      <div className={styles.nav}>
        <div className={styles.inner}>
          <Menu className={styles.menu} accordion>
            <Menu.MenuItem
              label={t('layouts.sidebar.backendInventory')}
              to={ROUTE_PATH.inventory}
            />
            <Menu.MenuItem
              label={t('layouts.sidebar.backendCalendar')}
              to={ROUTE_PATH.calendar}
            />
          </Menu>
        </div>
      </div>
    </aside>
  );
};
const ObserverSidebar = observer(Sidebar);
export default ObserverSidebar;
