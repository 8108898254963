import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { Menu } from '@linktivity/link-ui';
import { ROUTE_PATH } from '@link/gds-share/constants/path';
import { useStore } from '@link/gds-supplier/stores';

import styles from './sidebar.module.css';

const Sidebar = () => {
  const { t } = useTranslation();
  const { auth } = useStore();

  return (
    <aside className={styles.sidebar}>
      <div className={styles.nav}>
        <div className={styles.inner}>
          {auth.isClaimsLoaded && (
            <Menu className={styles.menu} accordion>
              <Menu.MenuItem
                label={t('layouts.sidebar.allotmentSetting')}
                to={ROUTE_PATH.setting}
              />
              <Menu.MenuItem
                label={t('layouts.sidebar.allotmentCalendar')}
                to={ROUTE_PATH.calendar}
              />
              {!auth.isSupplierAdmin && (
                <>
                  <Menu.MenuItem
                    label={t('layouts.sidebar.allotmentSegmentId')}
                    to={ROUTE_PATH.segmentId}
                  />
                  <Menu.MenuItem
                    label={t('layouts.sidebar.allotmentException')}
                    to={ROUTE_PATH.exception}
                  />
                  <Menu.MenuItem
                    label={t('layouts.sidebar.allotmentOtaGroup')}
                    to={ROUTE_PATH.otaGroup}
                  />
                  <Menu.MenuItem
                    label={t('layouts.sidebar.allotmentBackend')}
                    to={ROUTE_PATH.backend}
                  />
                </>
              )}
            </Menu>
          )}
        </div>
      </div>
    </aside>
  );
};
const ObserverSidebar = observer(Sidebar);
export default ObserverSidebar;
