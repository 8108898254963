import React from 'react';
import { Outlet } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useStore } from '@link/gds-supplier/stores';
import { Loader } from '@link/gds-share/components';
import Header from '../Header';
import Sidebar from '../Sidebar';
import styles from './base.module.css';

const BaseLayout: React.FC = () => {
  const { auth } = useStore();
  return (
    <div className={styles.base}>
      <Header />
      <div className={styles.container}>
        <Sidebar />
        <main className={styles.main}>
          <div className={styles.inner}>
            {auth.isClaimsLoaded ? <Outlet /> : <Loader />}
          </div>
        </main>
      </div>
    </div>
  );
};
const ObserverBaseLayout = observer(BaseLayout);
export default ObserverBaseLayout;
